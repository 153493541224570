import React from 'react'
import {Link} from 'gatsby'

const BlogSidebar = () => {
    return (
        <div className="widget-area">
            
            <div className="widget widget_tag_cloud">
                <h3 className="widget-title">Popular Tags</h3>

                <div className="tagcloud">
                    <Link to="/blog">
                        Occupational Therapy <span className="tag-link-count">(3)</span>
                    </Link>
                    <Link to="/blog">
                        Life Style <span className="tag-link-count">(3)</span>
                    </Link>
                    <Link to="/blog">
                        Tips <span className="tag-link-count">(2)</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default BlogSidebar